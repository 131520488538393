<template>
  <div class="autocomplete-item p-3">

    <div class="flex items-center justify-start">
      <div class="px-2 mr-2">
        <feather-icon :icon="item.icon" svg-classes="w-7 h-7"/>
      </div>
      <div>
        <div>{{ item && item.title }}</div>
        <div class="text-sm text-muted">{{item && item.subtitle}}</div>
      </div>
    </div>
  </div>

  
</template>
 
<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script> 

<style>
.autocomplete-item {
  background-color: white;
  width: 100%;
}
</style>