<template>
  <div>
    <vx-card class="mb-4" >
      <div class="vx-row">
        <div class="vx-col w-1/4">
          <vs-input class="w-full" v-model="widgetInstanceName" placeholder="Give your widget a name..." v-validate="'required|max:50'" name="Widget Name" />
        </div>
        <div class="vx-col w-1/4">
          <div class="flex flex-wrap">
            <vs-button color="danger" class="mr-4" @click="showCancelDialog=true">Cancel</vs-button>
            <vs-button @click="mode=='edit' ? saveWidget() : addWidget()">Save</vs-button>
          </div>
        </div>
        <div class="vx-col w-1/4">
          <div class="flex flex-wrap justify-center">
            <vs-button @click="switchScreen('preview-desktop')" class="ml-2" :class="{'active-button': previewClass === 'preview-desktop'}" 
              type="border" icon-pack="feather" icon="icon-monitor"></vs-button>
            <vs-button @click="switchScreen('preview-tablet')" class="ml-2" :class="{'active-button': previewClass === 'preview-tablet'}"
              type="border" icon-pack="feather" icon="icon-tablet"></vs-button>
            <vs-button @click="switchScreen('preview-mobile')" class="ml-2" :class="{'active-button': previewClass === 'preview-mobile'}"
              type="border" icon-pack="feather" icon="icon-smartphone"></vs-button>
          </div>
        </div>
        <div class="vx-col w-1/4">
          <div class="flex flex-wrap justify-end mt-3">
            <h5>{{this.widgetName}}</h5>
          </div>
        </div>
      </div>
    </vx-card>
    
    <div class="vx-row">
      <div class="vx-col w-1/4 pl-4 pr-0" v-if="this.mode=='add' && !this.templateSelected && this.templates">
        <vx-card style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;">
          <vue-custom-scrollbar class="fixed-height-widget-templates" :settings="{suppressScrollY: false,suppressScrollX: true, wheelPropagation: false}">
            <application-widget-templates :templates="templates" @continueWithTemplate="handleContinueWithTemplate" @templateSelected="handleTemplateSelected" />
          </vue-custom-scrollbar>
        </vx-card>
      </div>
      <div class="widget-editor-tab-container vx-col w-1/4 pr-0" v-else>
        <vx-card>
          <vs-tabs v-model='selectedTabIndex' class="tabs-shadow-none" id="profile-tabs" v-if="hasLoaded">
            <vs-tab 
              v-for="(categorizedSetting, index) in Object.keys(categorizedSettings)"  
              :key="index"
              :label="categorizedSetting">

              <vue-custom-scrollbar class="fixed-height-widget-editor" :settings="{suppressScrollY: false,suppressScrollX: true, wheelPropagation: false}">
                <div class="mt-0 ml-0 mr-5">
                  <div class="mt-2" 
                    v-for="(formField, index) in categorizedSettings[categorizedSetting]" 
                    :key="index">
                        <component 
                          :is="formField.component" 
                          v-model="settingsData[formField.key]"
                          v-bind="{ settings: formField.settings, applicationId: applicationId, allValues: settingsData, widgetUrl: widgetUrl, widgetInstanceId: widgetInstanceId  }">
                        </component>
                  </div>
                </div>
              </vue-custom-scrollbar>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>
      <div class="vx-col w-3/4 preview-container pl-0">
        <vx-card v-if="hasLoaded">
          <div class="flex flex-wrap justify-center fixed-height-widget-preview" style="height='100%'">
            <iframe id="previewFrame" :class="previewClass" class="widgio-widget" :src="iframe.src" width="100%" height="100%" style="border:0 solid #999;margin:0 auto;" ref="previewFrame" @load="loadConfig">
            </iframe>
          </div>
        </vx-card>
      </div>
    </div>

    <vs-prompt
        title="Are you sure you want to cancel?"
        accept-text="Yes, cancel changes"
        cancel-text="No, return to editor"
        close-button="false"
        @cancel="cancelChanges"
        @accept="cancel"
        :active.sync="showCancelDialog">

          <div class="vx-row mt-2 mb-4">
            <div class="vx-col">
              <span>All changes to this widget will be lost.</span>
            </div>
          </div>
      </vs-prompt>

  </div>
</template>

<script>
import TravioWidgetSlider from "./components/TravioWidgetSlider"
import TravioWidgetColorPicker from "./components/TravioWidgetColorPicker"
import TravioWidgetSelect from "./components/TravioWidgetSelect"
import TravioWidgetInput from "./components/TravioWidgetInput"
import TravioWidgetRichText from './components/TravioWidgetRichText.vue'
import TravioWidgetImagePicker from './components/TravioWidgetImagePicker.vue'
import TravioWidgetFileManager from './components/TravioWidgetFileManager.vue'
import TravioWidgetDateTime from './components/TravioWidgetDateTime.vue'
import TravioWidgetSwitch from './components/TravioWidgetSwitch.vue'
import TravioWidgetPopupSettings from './components/TravioWidgetPopupSettings.vue'
import TravioWidgetUnitValue from './components/TravioWidgetUnitValue.vue'
import TravioWidgetCollectionSet from './components/TravioWidgetCollectionSet.vue'
import TravioWidgetAutoComplete from './components/TravioWidgetAutoComplete'
import ApplicationWidgetTemplates from './ApplicationWidgetTemplates'

import applicationWidgetStore from './applicationWidgetStore'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import _ from 'lodash'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import * as appWidgetUtils from './applicationWidgetUtils.js'

export default {
  components: {
    ApplicationWidgetTemplates,
    TravioWidgetSlider,
    TravioWidgetColorPicker,
    TravioWidgetSelect,
    TravioWidgetInput,
    vueCustomScrollbar,
    TravioWidgetRichText,
    TravioWidgetDateTime,
    TravioWidgetSwitch,
    TravioWidgetImagePicker,
    TravioWidgetPopupSettings,
    TravioWidgetUnitValue,
    TravioWidgetCollectionSet,
    TravioWidgetAutoComplete,
    TravioWidgetFileManager
  },
  props: {
    widgetInstanceId: { required: false },
    widgetUrl: { type: String, required: true },
    applicationId: { required: true}
  },
  data () {
    return {
      selectedTabIndex: 0,
      widgetName: '',
      widgetInstanceName: '',
      widgetVersionId: 0,
      settingsData: {},
      formFieldSettings: [],
      hasLoaded: false,
      categorizedSettings: {},
      templates: null,
      /** For add mode only, must be set to true for edit */
      templateSelected: false,
      iframe: {
        src: '',
        loaded: false
      },
      previewClass: 'preview-desktop',
      showCancelDialog: false
    }
  },
  computed: {
    mode() {
      return this.widgetInstanceId ? 'edit' : 'add'
    },
    buttonTypeDesktop () {
      return this.previewClass === 'preview-desktop' ? 'filled' : 'border'
    },
    buttonTypeTablet () {
      return this.previewClass === 'preview-tablet' ? 'filled' : 'border'
    },
    buttonTypeMobile () {
      return this.previewClass === 'preview-mobile' ? 'filled' : 'border'
    }
  },
  created () {
    this.$store.commit('TOGGLE_FOOTER_VISIBILITY', false)
    this.minimizeVerticalNav(true)
  },
  mounted () {
    const iframeUrl = `${process.env.VUE_APP_API_BASE_URL}/widgetpreview`
    if( this.mode == 'edit') {
      this.$http.get(`api/widgets/apps/${this.applicationId}/instances/${this.widgetInstanceId}`)
        .then(response => {
          this.widgetVersionId = response.data.versionId
          this.widgetName = response.data.name
          this.widgetInstanceName = response.data.instanceName

          this.iframe.src = `${iframeUrl}?versionId=${this.widgetVersionId}`
          this.iframe.hasLoaded = true

          // https://codesource.io/how-to-dynamically-create-reactive-properties-in-vue/
          this.settingsData = appWidgetUtils.buildSettingsData(response.data.settings)
          this.formFieldSettings = appWidgetUtils.buildFormFields(response.data.installSettings.options.properties, this.settingsData)
          this.categorizedSettings = this.buildCategorizedSettings(this.formFieldSettings)
          this.hasLoaded = true
          this.templateSelected = true
        }).catch(err => console.error(err) )
    } else {
      this.$http.get(`api/widgets/apps/${this.applicationId}/templates/${this.widgetUrl}`)
        .then(response => {
          this.widgetName = response.data.name
          this.widgetVersionId = response.data.versionId
          this.templates = response.data.installSettings.options.templates

          this.iframe.src = `${iframeUrl}?versionId=${this.widgetVersionId}`
          this.iframe.hasLoaded = true

          const defaults = appWidgetUtils.buildSettingsDefaults(response.data.installSettings.options.properties)
          this.settingsData = Object.assign({}, defaults )
          
          this.formFieldSettings = appWidgetUtils.buildFormFields(response.data.installSettings.options.properties, this.settingsData)
          this.categorizedSettings = this.buildCategorizedSettings(this.formFieldSettings)
          this.hasLoaded = true
        }).catch(err => console.error(err) )
    }
  },
  watch: {
    settingsData: {
      deep: true,
      handler: _.debounce(function (val){
        const previewIframe = this.$refs.previewFrame
        if (previewIframe && previewIframe.contentWindow) {
          previewIframe.contentWindow.postMessage(val,'*')
        }
      }, 250)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('TOGGLE_FOOTER_VISIBILITY', true)
    this.minimizeVerticalNav(false)
    next()
  },
  methods: {
    buildCategorizedSettings(formFieldSettings) {
      return this.$_
        .groupBy(formFieldSettings, (item) => {
        return item.settings.category || 'Miscellaneous'
      })
    },
    handleContinueWithTemplate (templateSettings) {
      this.settingsData = Object.assign({}, this.settingsData, templateSettings )
      this.templateSelected = true
    },
    handleTemplateSelected (templateSettings) {
      this.settingsData = Object.assign({}, this.settingsData, templateSettings )
    },
    addWidget() {
      this.$vs.loading();
      const settingsData = JSON.stringify(this.settingsData)
      this.$http.post(`api/widgets/apps/${this.applicationId}/instances`, {
        widgetName: this.widgetInstanceName,
        widgetVersionId: this.widgetVersionId,
        settings: settingsData
      }).then(response => {
        this.$_notifySuccess('Widget has been saved')
        this.$router.push({ name: 'application-widget-instances', 
        params: { 
          widgetUrl: this.widgetUrl,
          applicationId: this.applicationId
        }
      })
      }).catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
      })
      .finally(()=> this.$vs.loading.close());

    },
    saveWidget() {
      this.$vs.loading();
      const settingsData = JSON.stringify(this.settingsData)
      this.$http.put(`api/widgets/apps/${this.applicationId}/instances`, {
        widgetInstanceId: parseInt(this.widgetInstanceId),
        widgetInstanceName: this.widgetInstanceName,
        settings: settingsData
      }).then(response => {
        this.$_notifySuccess('Widget has been saved')
        this.$router.push({ name: 'application-widget-instances', 
        params: { 
          widgetUrl: this.widgetUrl,
          applicationId: this.applicationId
        }
      })
      }).catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
      })
      .finally(()=> this.$vs.loading.close());

    },
    cancel () {
      this.$router.push({ name: 'application-widget-instances', 
        params: { 
          widgetUrl: this.widgetUrl,
          applicationId: this.applicationId
        }
      })
    },
    cancelChanges () {

    },
    switchScreen(classVal) {
      this.previewClass = classVal
    },
    loadConfig() {
      // This is to trigger widget preview during initialization, see watcher for this.settingsData
      this.settingsData = Object.assign({}, this.settingsData )
    },
    minimizeVerticalNav(val) {
      this.$store.commit('TOGGLE_REDUCE_BUTTON', val)
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', !val)
      this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', !val)
      this.$store.dispatch('updateVerticalNavMenuWidth', 'reduced')
    }
  }
}
</script>

<style scoped lang="scss">
  .fixed-height-widget-editor {
    height: calc(var(--vh, 1vh) * 100 - 23.8rem);
    overflow-y: scroll;
    position: relative;
    margin:auto
  }
  .fixed-height-widget-templates {
    height: calc(var(--vh, 1vh) * 100 - 19.7rem);
    overflow-y: scroll;
    position: relative;
    margin:auto
  }
  .fixed-height-widget-preview {
    height: calc(var(--vh, 1vh) * 100 - 16.65rem);
  }

  

  [class*="preview-"] {
    border-radius: 20px !important;
    
    transition: all 0.75s;
  }

  .preview-desktop {
    border-radius: 0 !important;
    width: 100%;
  }
  .preview-tablet {
    width: 772px;
  }
  .preview-mobile {
    width: 380px;
  }

</style>

<style>
.active-button {
  background-color: rgba(var(--vs-primary), 1) !important;
  color: white !important;
}
.active-button:hover{
  background-color: rgba(var(--vs-primary), 1) !important;
  color: white !important;
}

.widget-editor-tab-container .vx-card__body{
  padding-right: 0.1rem !important;
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.preview-container .vx-card__body {
  padding: 0px !important;
}

.preview-container .vx-card {
  box-shadow:  0px 0px 4px 0px rgb(0 0 0 / 10%) !important;
}

.widget-editor-tab-container .vx-card {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.preview-container .vx-card {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

</style>