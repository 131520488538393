<template>
  <div v-if="settings && show" class="widget-autocomplete" :class="marginClass">
    <h6 class="text-primary">{{ settings.title }}</h6>
    <div class="mb-2">
      <span v-if="settings.description" v-html="settings.description" class="text-sm"></span>
    </div>
    <v-autocomplete 
      :items="items"
      v-model="itemInput"
      :get-label="getLabel"
      :component-item='itemTemplate' 
      @update-items="updateItems" 
      @input="updateSettings">
    </v-autocomplete>
    <div v-if="showItem" class="vx-row mt-2 px-2 py-4">
      <div class="vx-col w-5/6">
        <div class="flex items-center justify-start">
          <div class="px-2 mr-2">
            <feather-icon :icon="item.icon" svg-classes="w-7 h-7"/>
          </div>
          <div>
            <div>{{ item && item.title }}</div>
            <div class="text-sm text-muted">{{item && item.subtitle}}</div>
          </div>
        </div>
      </div>
      <div class="vx-col w-1/6" style="text-align:right">
        <div class="p-2">
          <feather-icon icon="XCircleIcon" class="m-0 cursor-pointer" @click="clearSelection" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'
import TravioWidgetAutoCompleteItem from './TravioWidgetAutoCompleteItem.vue'
import { uuid } from 'vue-uuid'

export default {
  props: {
    value: { required: true },
    settings: { required: true },
    allValues: { required: true }
  },
  data () {
    return {
      uuid: uuid.v4(),
      itemTemplate: TravioWidgetAutoCompleteItem,
      item: {},
      itemInput: {},
      items: [],
      mapIcon: 'mdi-icon'
    }
  },
  computed: {
    marginClass () {
      return this.topMargin ? `mt-${this.topMargin}` : 'mt-6'
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    },
    showItem () {
      // Default value of this.item is an empty object, so we need to check for that
      return (!this.item || Object.keys(this.item).length === 0)  ? false : true
    }
    
  },
  mounted () {
    this.item = JSON.parse(JSON.stringify(this.value))
    this.itemInput = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    updateSettings (value) {
      this.item = value || this.item
      const emittedValue = value || {}
      this.$emit('input', emittedValue)
    },
    getLabel (item) {
      return item && item.title
    },
    updateItems (text) {
      const service = Object.keys(this.settings.services)[0]
      const scope = this.settings.services[service].scope
      fetch(`https://api.travelify.io/widgetdataautocomplete?svc=${service}&${scope}=${text}&sessionToken=${this.uuid}`)
      .then(res => res.json())
      .then(res => {
          if (res) {
            this.items = res
          }
      })
      .catch(err => {
          console.log(err)
      })
    },
    clearSelection () {
      this.item = {}
      this.itemInput = {}
    }
  }
}

</script>
<style>
.widget-autocomplete input {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    
}
.widget-autocomplete input:focus {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(var(--vs-primary),1)!important;
}

.widget-autocomplete .v-autocomplete-input {
  padding: .7rem;
  font-size: 1rem !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 5px;
  font-family: inherit;
  color:inherit;
  width: 100%;
}

.widget-autocomplete .v-autocomplete-list {
  width:100%;
} 

.widget-autocomplete .feather-icon {
  /* Fix to icon overlapping search results */
  position: inherit !important;
}



</style>