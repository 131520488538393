<template>
  <div>
    <div class="flex flex-wrap items-center">
      <h5 class="mt-3">{{params.data.fileName}}</h5>
      <feather-icon title="Edit" class="mt-2" icon="Edit3Icon" svgClasses="h-5 w-5 ml-4 hover:text-primary cursor-pointer" @click="onEdit" />
    </div>
    
    <div class="file-manager-file-description my-3">{{params.data.fileDescription}}</div>
    <div v-for="(keyword, index) in keywordList" :key="index">
      <vs-chip class="ag-grid-cell-chip mt-2 mr-2" color="success"><span>{{keyword}}</span></vs-chip>
    </div>
    <!-- <feather-icon title="Edit" v-if="true" icon="Edit3Icon" svgClasses="h-4 w-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <feather-icon title="Copy" icon="ClipboardIcon" svgClasses="h-4 w-4 ml-2 hover:text-primary cursor-pointer" @click="duplicateImage" />
    <feather-icon title="Delete" v-if="true" icon="TrashIcon" svgClasses="h-4 w-4 ml-2 hover:text-danger cursor-pointer" @click="deleteImage" /> -->
  </div>

</template>

<script>
import _ from 'lodash'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    keywordList () {
      return (this.params.data.keywords && this.params.data.keywords.split(',')) || []
    }
  },
  methods: {
    onEdit () {
      this.params.context.componentParent.onEditFileAttribute(this.params.data) 
    }
  }
}
</script>

<style lang="scss" scoped>
.file-manager-file-description {
  line-height: 24px;
}
</style>
