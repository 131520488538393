<template>
  <div class="travio-img-picker vx-row" :class="marginClass">
    <div class="vx-col w-full">
      <h6 class="text-primary mb-2">{{ settings.title }}</h6>
    </div>
    <div class="vx-col w-full mb-2">
      <div class="card">
        <div slot="no-body" class="travio-img-picker-container">
          <img :src="value" 
          class="responsive card-img-top">
        </div>
      </div>
    </div>
    <div class="vx-col w-1/2">
      <vs-button @click="selectImage" class="w-full" color="primary">SELECT IMAGE</vs-button>
    </div>
    <div class="vx-col w-1/2">
      <vs-button @click="clearImage" class="w-full" color="warning">CLEAR IMAGE</vs-button>
    </div>

    

    <vs-popup 
      title="Image Manager" 
      class-content=""
      :active.sync="showPickerPopup"
      data-backdrop="image-picker-popup"
      data-keyboard="true"
    >
      <vs-tabs class="tabs-shadow-none" v-model="activeTab">
        <vs-tab label="Image Gallery">
            <vue-custom-scrollbar class="fixed-height-popup-tab" :settings="{suppressScrollY: false,suppressScrollX: true, wheelPropagation: false}">
            <vs-input icon-pack="feather" icon="icon-search" v-model="searchTextImageGallery" placeholder="Search" class="w-full mb-base"/>
            <div class="vx-row" v-for="(image, index) in systemImages" :key="index" >
              <div class="vx-col w-3/4">
                <div class="flex flex-wrap justify-start items-center">
                  <vs-avatar :src="image.previewUrl" size="120px" />
                  <div class="ml-4">
                    <p class="font-semibold">{{image.fileName}}</p>
                    <small>{{image.category1}} / {{image.category2}}</small>
                  </div>
                </div>  
              </div>
              <div class="vx-col w-1/4">
                <div class="mt-10 ml-16">
                  <vs-button @click="selectImageFromPicker(image.fullUrl)" class="w-full">Select</vs-button>
                </div>  
              </div>
            </div>
          </vue-custom-scrollbar>
        </vs-tab>
        <vs-tab label="Image By URL">
          <vs-input v-model="publicImageUrl" placeholder="https://example.com/image.jpg" class="w-full mt-8 mb-base"/>
            <div class="flex flex-wrap justify-end">
              <vs-button @click="closePopup"  class="float-right" type="border">Cancel</vs-button>
              <vs-button @click="selectPublicImage"  class="float-right ml-2" color="primary" type="filled">OK</vs-button>
            </div>
        </vs-tab>
        <vs-tab label="My Images">
          <vue-custom-scrollbar class="fixed-height-popup-tab" :settings="{suppressScrollY: false,suppressScrollX: true, wheelPropagation: false}">
            <vs-input icon-pack="feather" icon="icon-search" v-model="searchTextUserImages" placeholder="Search" class="w-full mb-base"/>
            <div class="vx-row" v-for="(image, index) in userImages" :key="index" >
              <div class="vx-col w-3/4">
                <div class="flex flex-wrap justify-start items-center">
                  <vs-avatar :src="image.previewUrl" size="120px" />
                  <div class="ml-4">
                    <p class="font-semibold">{{image.fileName}}</p>
                    <small>{{image.fileSize}} kb</small>
                  </div>
                </div>  
              </div>
              <div class="vx-col w-1/4">
                <div class="mt-4 ml-16">
                  <vs-button @click="selectImageFromPicker(image.fullUrl)" class="w-full">Select</vs-button>
                  <vs-button @click="showDeleteImagePopup=true; selectedFileKeyToRemove = image.fileKey" class="w-full mt-3" color="danger">Delete</vs-button>
                </div>  
              </div>
            </div>
            <div class="flex flex-wrap justify-center">
              <input type="file" multiple hidden id="uploadImpageInput" ref="uploadImpageInput" v-on:change="handleFileUpload"/>
              <vs-button @click="$refs.uploadImpageInput.click()"  class="float-right ml-2" color="primary" type="filled">Upload Image(s)</vs-button>      
            </div>
          </vue-custom-scrollbar>
        </vs-tab>
      </vs-tabs>

      <vs-popup
        title="Delete Image"
        :active.sync="showDeleteImagePopup">

          <div class="vx-row mt-2 mb-2">
            <div class="vx-col w-full">
              <div class="">Are you sure you want to delete this image?</div>
            </div>
          </div>
          <div class="flex flex-wrap justify-end">
            <vs-button @click="showDeleteImagePopup=false"  class="float-right" type="border">Cancel</vs-button>
            <vs-button @click="removeImageFromPicker(); showDeleteImagePopup=false" class="float-right ml-2" color="primary" type="filled">OK</vs-button>
          </div>
      </vs-popup>
    </vs-popup>


  </div>
</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
export default {
  components: {
    vueCustomScrollbar
  },
  props:{
    value: { required: true },
    settings: { required: true },
    applicationId: { required: true},
    topMargin: { required: false }
  },
  data () {
    return {
      showPickerPopup: false,
      filesForUpload: null,
      images: [],
      searchTextUserImages: '',
      searchTextImageGallery: '',
      publicImageUrl: '',
      activeTab: 0,
      showDeleteImagePopup: false,
      selectedFileKeyToRemove: ''
    }
  },
  computed: {
    systemImages () {
      const lodash = this.$_
      const searchText = this.searchTextImageGallery.toLowerCase()
      return this.$_.filter(
        this.images,
        x => x.isSystem && lodash.includes(x.fileName && x.fileName.toLowerCase(), searchText))
    },
    userImages () {
      const lodash = this.$_
      const searchText = this.searchTextUserImages.toLowerCase()
      return this.$_.filter(
        this.images,
        x => !x.isSystem && lodash.includes(x.fileName && x.fileName.toLowerCase(), searchText))
    },
    marginClass () {
      return this.topMargin ? `mt-${this.topMargin}` : 'mt-10'
    }
  },
  mounted () {
  },
  methods: {
    selectImage () {
      this.$vs.loading()

      this.$http.get(`api/widgets/apps/${this.applicationId}/files`)
        .then(response => {
          this.images = response.data
        }).catch(err => console.error(err) )
        .finally(()=> this.$vs.loading.close());
      this.showPickerPopup = true
    },
    clearImage () {
      this.model = null
      this.$emit('input', null)
    },
    selectImageFromPicker (imageUrl) {
      this.model = imageUrl
      this.$emit('input', imageUrl)
      this.showPickerPopup = false
    },
    removeImageFromPicker() {
      const fileKey = this.selectedFileKeyToRemove
      this.$vs.loading()
      this.$http.delete(`api/widgets/apps/${this.applicationId}/files/${fileKey}`)
        .then(response => {
          this.images = this.$_.filter(this.images, x => !x.isSystem && x.fileKey !== fileKey)
          this.$_notifySuccess('Image successfully deleted')
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close())
    },
    updateSettings (value) {
      this.$emit('input', value)
    },
    handleFileUpload() {
      this.filesForUpload = Array.from(this.$refs.uploadImpageInput.files)
      if (this.filesForUpload) this.submitImage();
    },
    submitImage () {
      let formData = new FormData()
      this.$_.forEach(this.filesForUpload, (item, index) => {
        formData.append('imageFiles', item);
      })
      formData.append('singleFile', this.filesForUpload[0]);
      this.$vs.loading()
      this.$http.post( `api/widgets/apps/${this.$store.state.ActiveApplicationId}/files`, formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
      })
      .then(response => {
        this.images = this.images.concat(response.data)
        this.$_notifySuccess('Image(s) successfully uploaded')
      })
      .catch(error => this.$_notifyFailure('Image(s) failed to upload'))
      .finally(() => this.$vs.loading.close())
    },
    closePopup () {
      this.showPickerPopup = false
    },
    selectPublicImage () {
      if(this.publicImageUrl && this.validURL(this.publicImageUrl)) {
        this.$emit('input', this.publicImageUrl)
        this.showPickerPopup = false
      }
    },
    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    }

  }
}
</script>

<style>
/* .travio-img-picker .travio-img-picker-container{
    max-height: 140px;
    overflow: hidden;
    border-radius: 5px;
} */

/* vs-popup is outside the vue app element so we need this selector to scope styling changes */
/* [data-backdrop="image-picker-popup"] .vs-popup {
  width: 800px !important;
  max-height: calc(100% - 300px) !important;
  height: calc(100% - 300px) !important;
}

.fixed-height-popup-tab {
  height: calc(var(--vh, 1vh) * 100 - 30rem);
  overflow-y: scroll;
  position: relative;
  margin:auto
} */
</style>
