<template>
  <div v-if="settings && show" class="file-manager vx-row" :class="marginClass">
    <div v-if="settings.format=='file' && fileName" class="vx-col w-full">
      <vs-input class="w-full mb-2" :value="fileName" disabled />
    </div>
    <div v-if="settings.format=='file' && fileName" class="vx-col w-1/2">
      <vs-button @click="selectImage" class="w-full" color="primary">SELECT FILE</vs-button>
    </div>
    <div v-if="settings.format=='file' && fileName" class="vx-col w-1/2">
      <vs-button @click="clearImage" class="w-full" color="warning">CLEAR FILE</vs-button>
    </div>

    <div v-if="settings.format=='file' && !fileName" class="vx-col w-full">
      <vs-button @click="selectImage" class="w-full" color="primary">ADD FILE</vs-button>
    </div>

    <div v-show="settings.format=='image'" class="vx-col w-full">
      <h6 class="text-primary mb-2">{{ settings.title }}</h6>
    </div>
    <div v-show="settings.format=='image'" class="vx-col w-full mb-2">
      <div class="card">
        <div slot="no-body" class="file-manager-container">
          <img :src="value" 
          class="responsive card-img-top">
        </div>
      </div>
    </div>
    <div v-show="settings.format=='image'" class="vx-col w-1/2">
      <vs-button @click="selectImage" class="w-full" color="primary">SELECT IMAGE</vs-button>
    </div>
    <div v-show="settings.format=='image'" class="vx-col w-1/2">
      <vs-button @click="clearImage" class="w-full" color="warning">CLEAR IMAGE</vs-button>
    </div>

    <vs-popup 
      :fullscreen="true"
      title="File Manager" 
      class-content="file-manager"
      :active.sync="showPickerPopup"
      data-backdrop="image-picker-popup"
      data-keyboard="true"
    >
    <div v-show="!editorIframe.loaded" class="vx-row">
      <div class="vx-col w-full px-0">
        <vs-input  icon-pack="feather" icon="icon-search" v-model="searchTextImageGallery" placeholder="Search" class="w-full file-manager-search" />
      </div>
    </div>
    <div v-show="!editorIframe.loaded" class="vx-row">
      <div class="vx-col w-1/6 file-manager-sidebar">
        <input type="file" multiple hidden id="uploadImpageInput" ref="uploadImpageInput" v-on:change="handleFileUpload"/>
        <vs-button @click="$refs.uploadImpageInput.click()"  class="w-full mt-4 mb-3" color="primary" type="filled">Upload Files</vs-button>      
        <vs-button class="w-full" color="primary" @click="publicImageUrl='';showImportFromWebsitePopup=true" >Import From Website</vs-button>      
        <div class="flex flex-wrap justify-start mt-4 px-3 py-3 side-bar-item" :class="{ active: activeTab == 0}" @click="setActiveTab(0)" >
          <feather-icon title="Select" v-if="true" icon="HomeIcon" svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
          <h6 class="ml-2">Dashboard</h6>
        </div>
        <div class="flex flex-wrap justify-start px-3 py-3 side-bar-item" :class="{ active: activeTab == 1}" @click="setActiveTab(1)">
          <feather-icon title="Select" v-if="true" icon="ImageIcon" svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
          <h6 class="ml-2">My Images</h6>
        </div>
        
        <div class="flex flex-wrap justify-start px-3 py-3 side-bar-item" :class="{ active: activeTab == 2}" @click="setActiveTab(2)">
          <feather-icon title="Select" v-if="true" icon="FileIcon" svgClasses="h-4 w-4 hover:text-primary cursor-pointer"/>
          <h6 class="ml-2">My Documents</h6>
        </div>
      </div>
      <div class="vx-col w-5/6 file-manager-main-content">
          <vue-custom-scrollbar class="fixed-height-main-scroll-area" :settings="{suppressScrollY: false,suppressScrollX: true, wheelPropagation: false}">
            <div v-if="activeTab==0 && !searchTextImageGallery">
              <h4 class="mt-4">Dashboard</h4>
              <div class="mt-5">
                <travio-widget-file-manager-gallery :applicationId="applicationId" :fileList="freeImages" @onEditFileAttribute="editFileAttribute($event)" @onSelectedFile="selectImageFromPicker($event)" />
              </div>
              <div class="file-manager-tab-content" v-if="showPickerPopup">
                <travio-widget-file-manager-file-list :applicationId="applicationId" :fileList="userRecentFiles"
                  :widgetUrl="widgetUrl"
                  :widgetInstanceId="widgetInstanceId"
                  @onEditFileAttribute="editFileAttribute($event)"
                  @onSelectedFile="selectImageFromPicker($event)"
                  @onDeleteFile="confirmFileDelete($event)"
                  @onEditFile="handleFileEdit($event)"
                />
              </div>
            </div>
            <div v-if="activeTab==1 && !searchTextImageGallery">
              <h4 class="mt-4">My Images</h4>
              <div class="file-manager-tab-content" v-if="showPickerPopup">
                <travio-widget-file-manager-file-list :applicationId="applicationId" :fileList="userImages" 
                  :widgetUrl="widgetUrl"
                  :widgetInstanceId="widgetInstanceId"
                  @onEditFileAttribute="editFileAttribute($event)"
                  @onSelectedFile="selectImageFromPicker($event)"
                  @onDeleteFile="confirmFileDelete($event)"
                  @onEditFile="handleFileEdit($event)"
                />
              </div>
            </div>
            <div v-if="activeTab==2 && !searchTextImageGallery">
              <h4 class="mt-4">My Documents</h4>
              <div class="file-manager-tab-content" v-if="showPickerPopup">
                <travio-widget-file-manager-file-list :applicationId="applicationId" :fileList="userDocuments" 
                  :widgetUrl="widgetUrl"
                  :widgetInstanceId="widgetInstanceId"
                  @onEditFileAttribute="editFileAttribute($event)"
                  @onSelectedFile="selectImageFromPicker($event)"
                  @onDeleteFile="confirmFileDelete($event)"
                />
              </div>
            </div>
            <div v-if="searchTextImageGallery">
              <h4 class="mt-4">Search Results</h4>
              <div class="file-manager-tab-content" v-if="showPickerPopup">
                <travio-widget-file-manager-file-list :applicationId="applicationId" :fileList="searchedImages" 
                  :widgetUrl="widgetUrl"
                  :widgetInstanceId="widgetInstanceId"
                  @onEditFileAttribute="editFileAttribute($event)"
                  @onSelectedFile="selectImageFromPicker($event)"
                  @onDeleteFile="confirmFileDelete($event)"
                />
              </div>
            </div>
          </vue-custom-scrollbar>    
      </div>
    </div>
    <div v-if="editorIframe.loaded" class="vx-row file-manager-editor">
      <iframe id="previewFrame" :src="editorIframe.src" width="100%" height="100%" style="border:0 solid #999;margin:0 auto;" ref="previewFrame">
      </iframe>
    </div>
      <vs-popup
        title="Edit My File"
        :active.sync="showFileEditorPopup">

          <div class="vx-row mt-2 mb-2">
            <div class="vx-col w-full">
              <vs-input class="w-full" label="Name" v-model="currentFileInEdit.fileName" />
              <vs-input class="w-full mt-4" label="Description" v-model="currentFileInEdit.fileDescription" />
              <vs-input class="w-full mt-4 mb-2" label="Keywords" v-model="currentFileInEdit.keywords" />
              <span class="text-sm mb-4">Please enter keywords in comma seperated format</span>
            </div>
          </div>
          <div class="flex flex-wrap justify-end">
            <vs-button @click="showFileEditorPopup=false" class="float-right" type="border">Cancel</vs-button>
            <vs-button @click="saveFileAttribute" class="float-right ml-2" color="primary" type="filled">OK</vs-button>
          </div>
      </vs-popup>
      <vs-popup
        title="Import From Website"
        :active.sync="showImportFromWebsitePopup">

          <div class="mt-2 mb-2">
            <label>To import an image or file from a remote website, just enter the URL of the file below:</label>
            <vs-input v-model="publicImageUrl" placeholder="https://externalwebsite.com/filename.ext" class="w-full mt-8 mb-base"/>
          </div>
          <div class="flex flex-wrap justify-end">
            <vs-button @click="showImportFromWebsitePopup=false" class="float-right" type="border">Cancel</vs-button>
            <vs-button @click="selectPublicImage" class="float-right ml-2" color="primary" type="filled">OK</vs-button>
          </div>
      </vs-popup>
      <vs-popup
        title="Delete Image"
        :active.sync="showDeleteFilePopup">

          <div class="vx-row mt-2 mb-2">
            <div class="vx-col w-full">
              <div class="">Are you sure you want to delete the file {{selectedFileNameToRemove}}?</div>
            </div>
          </div>
          <div class="flex flex-wrap justify-end">
            <vs-button @click="showDeleteFilePopup=false"  class="float-right" type="border">Cancel</vs-button>
            <vs-button @click="removeImageFromPicker" class="float-right ml-2" color="primary" type="filled">OK</vs-button>
          </div>
      </vs-popup>
    </vs-popup>


  </div>
</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
import TravioWidgetFileManagerFileList from './TravioWidgetFileManagerFileList.vue'
import TravioWidgetFileManagerGallery from './TravioWidgetFileManagerGallery.vue'
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  components: {
    vueCustomScrollbar,
    TravioWidgetFileManagerFileList,
    TravioWidgetFileManagerGallery
  },
  props:{
    value: { required: true },
    settings: { required: true },
    allValues: { required: true },
    applicationId: { required: true},
    widgetUrl: { required: false },
    widgetInstanceId: { required: false },
    topMargin: { required: false }
  },
  data () {
    return {
      showPickerPopup: false,
      filesForUpload: null,
      images: [],
      searchTextUserImages: '',
      searchTextImageGallery: '',
      publicImageUrl: '',
      activeTab: 0,
      showDeleteFilePopup: false,
      showFileEditorPopup: false,
      showImportFromWebsitePopup: false,
      selectedFileKeyToRemove: '',
      selectedFileNameToRemove: '',
      currentFileInEdit: {
        fileId: 0,
        fileName: '',
        fileDescription: '',
        keywords: ''

      },
      freeImages: [],
      editorIframe: {
        src: '',
        loaded: false
      }
    }
  },
  computed: {
    systemImages () {
      const lodash = this.$_
      const searchText = this.searchTextImageGallery.toLowerCase()
      return this.$_.filter(
        this.images,
        x => x.isSystem && lodash.includes(x.fileName && x.fileName.toLowerCase(), searchText))
    },
    userRecentFiles () {
      const lodash = this.$_
      const searchText = this.searchTextUserImages.toLowerCase()
      return this.$_.filter(
        this.images,
        x => !x.isSystem && lodash.includes(x.fileName && x.fileName.toLowerCase(), searchText))
    },
    userImages () {
      const lodash = this.$_
      const searchText = this.searchTextUserImages.toLowerCase()
      return this.$_.filter(
        this.images,
        x => !x.isSystem 
          && x.fileType === 'image'
          && lodash.includes(x.fileName && x.fileName.toLowerCase(), searchText))
    },
    userDocuments () {
      const lodash = this.$_
      const searchText = this.searchTextUserImages.toLowerCase()
      return this.$_.filter(
        this.images,
        x => !x.isSystem 
          && x.fileType === 'generic'
          && lodash.includes(x.fileName && x.fileName.toLowerCase(), searchText))
    },
    searchedImages () {
      const lodash = this.$_
      const searchText = this.searchTextImageGallery.toLowerCase()
      return this.$_.filter(
        this.images, x => lodash.includes(x.fileName && x.fileName.toLowerCase(), searchText))
    },
    marginClass () {
      return this.topMargin ? `mt-${this.topMargin}` : 'mt-10'
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    },
    fileName () {
      return (this.value && this.value.substring(this.value.lastIndexOf('/') + 1)) || ''
    }
  },
  mounted () {
  },
  methods: {
    selectImage () {
      this.$vs.loading()

      this.$http.get(`api/widgets/apps/${this.applicationId}/fileSets`)
        .then(response => {
          this.images = response.data.files
          this.freeImages = response.data.freeImages
        }).catch(err => console.error(err) )
        .finally(()=> this.$vs.loading.close());
      this.showPickerPopup = true
    },
    clearImage () {
      this.model = null
      this.$emit('input', null)
    },
    selectImageFromPicker (imageUrl) {
      this.model = imageUrl
      this.$emit('input', imageUrl)
      this.showPickerPopup = false
    },
    confirmFileDelete(file) {
      //This will be called by cell renderer
      this.selectedFileKeyToRemove = file.fileKey
      this.selectedFileNameToRemove = file.fileName
      this.showDeleteFilePopup = true
    },
    removeImageFromPicker() {
      if (!this.selectedFileKeyToRemove) {
        this.$_notifyFailure('No image selected for deletion')
        return
      }
      this.$vs.loading()
      this.$http.delete(`api/widgets/apps/${this.applicationId}/files/${this.selectedFileKeyToRemove}`)
        .then(response => {
          this.images = this.$_.filter(this.images, x => !x.isSystem && x.fileKey !== this.selectedFileKeyToRemove)
          // Reassign images to trigger change detection
          this.images = JSON.parse(JSON.stringify(this.images))
          this.$_notifySuccess('Image successfully deleted')
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => {
          this.showDeleteFilePopup = false
          this.$vs.loading.close()
        })
    },
    updateSettings (value) {
      this.$emit('input', value)
    },
    handleFileUpload() {
      this.filesForUpload = Array.from(this.$refs.uploadImpageInput.files)
      if (this.filesForUpload) this.submitImage();
    },
    handleFileEdit (editUrl) {
      this.editorIframe.src = editUrl
      this.editorIframe.loaded = true
    },
    submitImage () {
      let formData = new FormData()
      this.$_.forEach(this.filesForUpload, (item, index) => {
        formData.append('imageFiles', item);
      })
      formData.append('singleFile', this.filesForUpload[0]);
      this.$vs.loading()
      this.$http.post( `api/widgets/apps/${this.$store.state.ActiveApplicationId}/files`, formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
      })
      .then(response => {
        this.images = this.images.concat(response.data)
        this.$_notifySuccess('Image(s) successfully uploaded')
      })
      .catch(error => this.$_notifyFailure('Image(s) failed to upload'))
      .finally(() => this.$vs.loading.close())
    },
    closePopup () {
      this.showPickerPopup = false
    },
    selectPublicImage () {
        this.$vs.loading()
        this.$http.post(`api/widgets/apps/${this.applicationId}/files/public`, { fileUrl: this.publicImageUrl })
        .then(response => {
          this.images.push(response.data)
          this.images = JSON.parse(JSON.stringify(this.images))
          this.showImportFromWebsitePopup = false
          this.$_notifySuccess('Image Uploaded')
        })
        .catch(error => { 
          error.response && error.response.data && this.$_notifyFailureByResponseData(error.response.data)
          console.log(error)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },
    setActiveTab (index) {
      this.activeTab = index
    },
    editFileAttribute (file) {
      this.currentFileInEdit = Object.assign({}, file)
      this.showFileEditorPopup = true
    },
    saveFileAttribute () {
      this.$vs.loading();
      const userId = parseInt(this.userId)
      this.$http.put(`api/widgets/apps/${this.applicationId}/files/${this.currentFileInEdit.fileId}`, this.currentFileInEdit )
      .then(response => {
        const index = this.$_.findIndex(this.images, i => i.fileId == this.currentFileInEdit.fileId)
        this.images[index] = Object.assign({}, this.images[index], this.currentFileInEdit) 
        //update images array to trigger change detection
        this.images = JSON.parse(JSON.stringify(this.images))
        this.showFileEditorPopup = false
      })
      .catch(error => {
        (error.response && this.$_notifyFailureByResponseData(error.response.data)) || console.error(error)
      })
      .finally(() => this.$vs.loading.close())
    }

  }
}
</script>

<style>
/* See class-content of vs-popup element above*/
/* .file-manager {
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  max-height: 100% !important;
} */
.file-manager {
  max-height: 100% !important;
}
.file-manager .file-manager-sidebar {
  background-color: #f8f9fa !important;
  height: calc(var(--vh, 1vh) * 100 - 7.4rem);
  border-radius: 0 0 0 6px;
}

.file-manager .file-manager-search input{
  border-radius: 0 !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.file-manager .fixed-height-main-scroll-area {
  height: calc(var(--vh, 1vh) * 100 - 10rem);
  /* overflow-y: scroll; */
  position: relative;
  margin:auto
}

.file-manager .file-manager-tab-content {
  height: calc(var(--vh, 1vh) * 100 - 3.8rem);
  /* overflow-y: scroll; */
  position: relative;
  margin:auto
}

.file-manager .side-bar-item:hover {
  cursor: pointer;
  font-size: 130%;
}

.file-manager .side-bar-item.active h6,
.file-manager .side-bar-item.active .feather-icon
{
  color: rgba(var(--vs-primary), 1) !important;
}

.file-manager .file-manager-container{
    max-height: 140px;
    overflow: hidden;
    border-radius: 5px;
}

.file-manager .file-manager-editor {
  height: calc(var(--vh, 1vh) * 100 - 6.2rem);
}
</style>
