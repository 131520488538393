<template>
  <div>
    <feather-icon title="Select" v-if="true" icon="CheckCircleIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="onSelect" />
    <feather-icon title="View" icon="EyeIcon" svgClasses="h-5 w-5 ml-3 hover:text-primary cursor-pointer" @click="onView" />
    <feather-icon title="Edit" v-if="true" icon="Edit3Icon" svgClasses="h-5 w-5 ml-3 hover:text-primary cursor-pointer" @click="onEdit" />
    <feather-icon title="Delete" v-if="true" icon="TrashIcon" svgClasses="h-5 w-5 ml-3 hover:text-danger cursor-pointer" @click="onDelete" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    onSelect () {
      //This is a call to the parent component's onSelectedFile method
      this.params.context.componentParent.onSelectedFile(this.params.data) 
    },
    onView () {
      this.params.context.componentParent.onFullscreen(this.params.data) 
    },
    onEdit () {
      this.params.context.componentParent.onEditImage(this.params.data) 
    },
    onDelete () {
      this.params.context.componentParent.onDeleteFile(this.params.data) 
    }
  }
}
</script>
