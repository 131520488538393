<template>
  <div v-if="settings && show">
    <h6 class="text-primary mb-2">{{ settings.title }}</h6>
    <div v-if="settings.description" class="text-sm mt-2 mb-2 rich-text-html">
      <span v-html="settings.description"></span>
    </div>
    <quill-editor
      :value="value"
      :options="editorOption"
      @change="onEditorChange"
      @ready="onEditorReady($event)"
    />
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import debounce from 'lodash/debounce'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  components: {
    quillEditor,
  },
  props: {
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true }
  },
  data () {
    return {
      editorOption: {
        modules : {
          toolbar : [
            'bold', 'italic', 'underline', 'strike', 'link', { 'script': 'sub'}, { 'script': 'super' }
          ]
        }
      }
    }
  },
  computed: {
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  methods: {
    onEditorChange: debounce(function(value) {
      this.$emit('input', value.html)
    }, 466),
    onEditorReady(quill) {
    },
  }
}
</script>

<style scoped>
  .rich-text-html ul {
    list-style-type: square !important;
  }

</style>
