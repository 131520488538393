<template>
  <div>
    <!-- <vs-button @click="resetRowHeight">Reset Height</vs-button> -->
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="theFileList"
      :gridOptions="gridOptions"
      :rowSelection="rowSelection"
      :pagination="false"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :context="context"
      :domLayout="domLayout"
    >
    </ag-grid-vue>

    <FsLightbox
      :toggler="fullScreenToggle"
      :sources="fileUrls"
      :slide="fullScreenCurrentIndex"
      type="image"
      :types="[null, null, 'video']"
    />

  </div>
 
</template>

<script>

import {AgGridVue} from "ag-grid-vue";
import CellRendererFileManagerThumbnail from './../cell-renderers/CellRendererFileManagerThumbnail.vue'
import CellRendererFileManagerName from './../cell-renderers/CellRendererFileManagerName.vue'
import CellRendererFileManagerControls from './../cell-renderers/CellRendererFileManagerControls.vue'
import CellRendererFileManagerFileSize from './../cell-renderers/CellRendererFileManagerFileSize.vue'
import Vue from "vue"
import FsLightbox from "fslightbox-vue"
import { formatISO } from 'date-fns'

export default {
  components: {
    AgGridVue,
    CellRendererFileManagerThumbnail,
    CellRendererFileManagerName,
    CellRendererFileManagerFileSize,
    CellRendererFileManagerControls,
    FsLightbox
  },
  props:{
    fileList: { required: true, type: Array },
    applicationId: {required: true },
    widgetUrl: { required: false },
    widgetInstanceId: { required: false },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: { 
        CellRendererFileManagerThumbnail,
        CellRendererFileManagerName,
        CellRendererFileManagerFileSize,
        CellRendererFileManagerControls
      },
      context: null,
      domLayout: 'autoHeight',
      fullScreenToggle: false,
      fullScreenCurrentIndex: 0,
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    theFileList () {
      return this.fileList
    },
    fileUrls () {
      return this.fileList.map(x => x.fullUrl)
    }
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'fileId', hide: true },
      { field: 'fileKey', hide: true },
      { field: 'isSystem', hide: true },
      { field: 'fullUrl', hide: true },
      { field: 'previewUrl', hide: true },
      { field: 'fileExtension', hide: true },
      { field: 'fileDescription', hide: true },
      { field: 'keywords', hide: true },
      { headerName: '',
        field: 'previewUrl',
        sortable: true,
        suppressSizeToFit: false,
        width: 150,
        autoHeight: true,
        cellRendererFramework: Vue.extend(CellRendererFileManagerThumbnail) 
      },
      { headerName: 'Name', 
        field: 'fileName',
        sortable: true,
        suppressSizeToFit: false,
        width: 450,
        autoHeight: true,
        cellStyle: {'white-space': 'normal'}, // This will cause the description to wrap instead of truncated
        cellRendererFramework: Vue.extend(CellRendererFileManagerName) 
      }, 
      { headerName: 'Type', width: 140, sortable: true, field: 'fileType' },
      { 
        headerName: 'Date Added',
        field: 'dateAdded',
        sortable: true,
        suppressSizeToFit: true,
        width: 150,
        valueFormatter: (param) => param.value && formatISO( new Date(param.value) , { representation: 'date' }) 
      },
      { headerName: 'File Size', 
        field: 'fileSize',
        sortable: true,
        suppressSizeToFit: false,
        width: 120,
        autoHeight: true,
        cellRendererFramework: Vue.extend(CellRendererFileManagerFileSize) 
      },
      { headerName: 'Controls', 
        sortable: false,
        suppressSizeToFit: false,
        width: 250,
        autoHeight: true,
        cellRendererFramework: Vue.extend(CellRendererFileManagerControls) 
      }
    ]


    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      autoHeight: true,
      resizable: true,
      suppressMenu: true
    }
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }
  },
  created () {

  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
    // Resetting row height must be done during rendering of grid, otherwise rows for cellRenderer will not auto reszie
    this.gridApi.resetRowHeights()
  },
  methods: {
    onEditFileAttribute (file) {
      this.$emit('onEditFileAttribute', file)
    },
    onSelectedFile (file) {
      if (file) this.$emit('onSelectedFile', file.fullUrl)
    },
    onFullscreen (file) {
      // This will be called by cell renderer
      const currentIndex = this.$_.findIndex(this.theFileList, x => x.fileId == file.fileId)
      this.fullScreenCurrentIndex = currentIndex + 1 
      this.fullScreenToggle = !this.fullScreenToggle
    },
    onDeleteFile (file) {
      this.$emit('onDeleteFile', file)
    },
    onEditImage (file) {
      // const redirectUrl = `${window.location.origin}/applications/${this.applicationId}/widgets/${this.widgetUrl}/${this.widgetInstanceId}`
      const redirectUrl = `${process.env.VUE_APP_API_BASE_URL}/api/widgets/apps/${this.applicationId}/${this.widgetUrl}/${this.widgetInstanceId}/${file.fileKey}`
      let editUrl = `https://studio.pixelixe.com/#api?apiKey=OA4IIF8NOScYPR1RK14MGQlAKGElKxw5XzwuXg&templates=true&uid=
      ${encodeURIComponent(localStorage.getItem('access_token')
      )}&graphicName=${encodeURIComponent(file.fileName
      )}&imageUrl=${encodeURIComponent(file.fullUrl
      )}&outputURL=${encodeURIComponent(redirectUrl)}`;

      this.$emit('onEditFile', editUrl)

      // self.location.href = `https://studio.pixelixe.com/#api?apiKey=LGcCgLQKasNB1Fq8k9ppnoVAp143&templates=true&uid=${encodeURIComponent(
      //   localStorage.getItem('access_token')
      // )}&graphicName=${encodeURIComponent(
      //   file.fileName
      // )}&imageUrl=${encodeURIComponent(file.fullUrl)}`;
    }
  }
}
</script>

<style>
</style>