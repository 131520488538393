<template>
  <div v-if="settings && show" class="widget-input mt-6">
    <h6 class="text-primary mb-2">{{ settings.title }}</h6>
    <label class="text-sm">{{ settings.description || '' }}</label>
    <vs-switch v-model="model" @change="updateSettings($event.target.value)"/>
  </div>
</template>

<script>
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'
export default {
  props:{
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true }
  },
  data () {
    return {
      model: false
    }
  },
  computed: {
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  mounted () {
    this.model = this.value === true || this.value === 'true'
  },
  methods: {
    updateSettings (value) {
      this.$emit('input', this.model)
    }
  }
}
</script>
