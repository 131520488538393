<template>
  <div class="widget-valued-unit-input mt-8 mb-2" v-if="settings">
    <h6 class="text-primary mb-2">{{ settings.title }}</h6>
    <label class="text-sm">{{ settings.description || '' }}</label>
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <input
          type="number"
          class="w-full" 
          :value="unitValue.value"
          @input="updateValue($event.target.value)"
        />
      </div>
      <div class="vx-col w-1/2">
        <v-select class=""
          :value="selectedUnit"
          @input="updateUnit" 
          :options="options"
          :clearable="false" 
          />
      </div>
    </div>

  </div>
</template>

<script>
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  props:{
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true }
  },
  data () {
    return {
      selectedUnit: null,
      unitValue : { value: null, unit: null }
    }
  },
  computed: {
    options () {
      return this.settings.units || []
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  mounted () {
    this.unitValue = Object.assign({}, this.value)
    this.selectedUnit = this.value.unit || this.settings.default.unit || null
  },
  methods: {
    updateValue (value) {
      this.$emit('input', this.unitValue )
      this.unitValue = Object.assign(this.unitValue, { value: value })
    },
    updateUnit (value) {
      this.selectedUnit = value;
      this.unitValue = Object.assign(this.unitValue, { unit: value })
      this.$emit('input', this.unitValue )
    }
  }
}
</script>

<style scoped>
.widget-valued-unit-input input {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    
}
.widget-valued-unit-input input:focus {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(var(--vs-primary),1)!important;
}
</style>