<template>
  <div>
    <span>{{fileSizeKb}}</span>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    fileSizeKb () {
      
      // return (this.params.data.fileSize).toLocaleString('en') + ' KB'
      return this.formatBytes(this.params.data.fileSize, 2)
    }
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 KB';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
}
</script>
