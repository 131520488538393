<template>
  <div v-if="settings && show" class="widget-input" :class="marginClass">
    <h6 class="text-primary">{{ settings.title }}</h6>
    <div class="mb-2">
      <span v-if="settings.description" v-html="settings.description" class="text-sm"></span>
    </div>
    <input v-if="rowCount === 1"
      type="text"
      class="w-full text-sm" 
      :value="value"
      :maxlength="maxCharLength"
      @input="updateSettings($event.target.value)">
    <textarea v-if="rowCount > 1"
      :maxlength="maxCharLength"
      :rows="rowCount" 
      :value="value"
      class="w-full text-sm"
      :class="marginClass"
      @input="updateSettings($event.target.value)"
    />
    <div v-if="settings.maxLength" class="flex flex-wrap justify-end mt-1">
      <label class="text-sm">{{remainingCharsText}}</label>
    </div>
  </div>
</template>

<script>
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  props: {
    value: { required: true },
    settings: { required: true },
    allValues: { required: true },
    topMargin: { required: false },
    rows: { required: false, type: Number, default: 1 }
  },
  data () {
    return {
    }
  },
  computed: {
    maxCharLength () {
      return this.settings.maxLength || 100
    },
    rowCount () {
      const fmt = this.settings.format || 'string'
      if (fmt === 'textarea') return this.settings.rows || 5
      return this.settings.rows || 1
    },
    fieldFormat () {
      return this.settings.format || 'string'
    },
    remainingCharsText () {
      const remainingChars = this.maxCharLength - (this.value && this.value.length) || 0
      return `${remainingChars} / ${this.maxCharLength}`
    },
    marginClass () {
      return this.topMargin ? `mt-${this.topMargin}` : 'mt-6'
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
    
  },
  mounted () {
    // this.remainingChars = this.maxCharLength - this.value.length
  },
  methods: {
    updateSettings (value) {
      // this.value = value
      this.$emit('input', value)
    },
    updateCharLimit (value) {
      // Computed is the best solution but, due to use of vs-input limitation, this must do for now
      this.remainingChars = this.maxCharLength - value.length
    }
  }
}
</script>
<style scoped>
.widget-input input, .widget-input textarea {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    
}
.widget-input input:focus, .widget-input textarea:focus {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(var(--vs-primary),1)!important;
}

</style>
