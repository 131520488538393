<template>
  <transition name="modal">
    <div class="modal-mask" :style="{ zIndex: zIndex }">
      <div class="modal-wrapper">
        <div class="modal-container" :style="{ width: width + 'px' }">

          <div class="modal-header">
            <slot name="header">
            </slot>
          </div>

          <div class="modal-body">
            <vue-custom-scrollbar class="fixed-height-modal" :style="modalBodyStyles" :settings="{suppressScrollY: false,suppressScrollX: true, wheelPropagation: false}">
              <slot name="body">
              </slot>
            </vue-custom-scrollbar>

          </div>
          <vs-divider class="mb-0 mt-0" />
          <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  name: 'travio-modal',
  components: {
    vueCustomScrollbar
  },
  props: {
    width: { required:false, default: 400},
    height: { required:false },
    zIndex: { required:false, default: 42000 } // Navbar heading has zIndex of 41001
  },
  computed: {
    modalBodyStyles () {
      return this.height 
        ? { height: this.height + 'px' }
        : {}
    }
  }
}
</script>

<style>

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 400px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  /* font-family: "inherit"; */
}


.modal-header h3{
  /* width: 100%; */
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 5px 5px 0 0;
  position: relative;
  padding: 0.8rem;
  font-weight: 500;
  color: rgba(var(--vs-primary),1) !important;
  font-size: 1.2rem;
}

.modal-body {
  padding: 1rem;
  /* 
  // Uncomment to enable native scrolling
  overflow-y: scroll;
  height: 400px; */
}

.modal-footer {
  padding: 1rem;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.fixed-height-modal {
  height: calc(var(--vh, 1vh) * 100 - 20rem);
  max-height: 700px;
  overflow-y: scroll;
  position: relative;
  margin:auto
}

</style>