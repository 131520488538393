import _ from 'lodash'

export const buildSettingsData = (settingsJson) => {
  if (!settingsJson && !Array.isArray(settingsJson)) return {}
  if (!settingsJson && Array.isArray(settingsJson)) return []

  if (Array.isArray(settingsJson)) {
    return settingsJson
  }
  const settingsData = {}
  const settingsDataKeys = Object.keys(settingsJson)
  _.forEach(settingsDataKeys, (item, index) => {
    settingsData[item] = settingsJson[item]
  })
  return Object.assign({}, settingsData);
}

export const buildSettingsDefaults = (props) => {
  const propKeys = Object.keys(props)
  let defaults = {}
  _.forEach(propKeys, (item, index) => {
    if(props[item].type === 'object' && props[item].properties) {
      defaults[item] = buildSettingsDefaults(props[item].properties)
    } else {
      defaults[item] = props[item].default
    }
  })
  return defaults
}

export const buildFormFields = (fieldSettingsJson) =>  {
  if(!fieldSettingsJson) return
  var settingKeys = Object.keys(fieldSettingsJson)
  let formFieldSettings = []
  _.forEach(settingKeys, (item, index) => {
    let setting = fieldSettingsJson[item]
    let component = ''
    if(setting.type === 'number' || setting.type === 'integer' || setting.type === 'money')
      component = 'TravioWidgetSlider'
    else if(_.includes(['date-time','date','time'], setting.type) || _.includes(['date-time','date','time'], setting.format))
      component = 'TravioWidgetDateTime'
    else if (setting.format == 'color' || setting.type == 'color' )
      component = 'TravioWidgetColorPicker'
    else if (setting.format == 'image' || setting.format == 'file' )
      component = 'TravioWidgetFileManager'
    else if (setting.format === 'richtext' || setting.type === 'richtext')
      component = 'TravioWidgetRichText'
    else if (setting.enum || setting.format === 'font')
      component = 'TravioWidgetSelect'
    else if (setting.type === 'boolean')
      component = 'TravioWidgetSwitch'
    else if (setting.type === 'string' || setting.maxLength)
      component = 'TravioWidgetInput'
    else if (setting.type === 'autocomplete')
      // TODO: implement component for autocomplete, right now its only input
      component = 'TravioWidgetAutoComplete'
    else if (setting.type === 'object' && setting.units)
      component = 'TravioWidgetUnitValue'
    else if (setting.type === 'object')
      component = 'TravioWidgetPopupSettings'
    else if (setting.type === 'array')
      component = 'TravioWidgetCollectionSet'

    formFieldSettings.push({ key: item, component: component, settings: setting })
  })
  return formFieldSettings;
}

export const showField = (setting, allSettingsValue) => {
  if(!setting) return false
  // Check if field is visible based on value of other fields
  if (setting.showIf) {
    let showCount = 0
    let showMatch = 0
    //Get props and values as an array eg {UnderageAction: "message", Validation, "test" } => [["UnderageAction", "message"], [["Validation", "test"]]]
    const conditions = Object.entries(setting.showIf)
    for (const [key, value] of conditions) {
      showCount++
      if(allSettingsValue[key] == value) showMatch++
    }
    return showCount === showMatch
  }
  return true
}
