<template>
  <div>
    <h5>Select a template to start with...</h5>
    <div class="vx-row match-height">
      <div class="vx-col w-1/2 mt-8" 
        v-for="(template, index) in templates"
        :key="index">
        <vx-card class="template-card" 
          v-ripple
          :class="{ 'selected-template': selectedTemplate === template, 'unselected-template': selectedTemplate !== template }" 
          @click="setSelectedTemplate(template)">

          <div slot="no-body">
            <img :src="template.imageUrl" 
            @error="setAltImgForThumbnail($event, template.title)"
            class="responsive card-img-top">
          </div>
          <div class="mt-3">
            <p style="text-align: center">{{template.title}}</p>
          </div>
        </vx-card>
      </div>
    </div>
    <vs-button class="mt-4 mb-2 w-full" @click="continueWithTemplate">CONTINUE WITH THIS TEMPLATE</vs-button>
  </div>

</template>

<script>
export default {
  props:{
    templates: { type: Array, required: true }
  },
  data () {
    return {
      selectedTemplate: null
    }
  },
  computed: {
    
  },
  mounted () {
    this.selectedTemplate = this.templates[0]
    this.$emit('templateSelected', this.selectedTemplate.settings)
  },
  methods: {
    continueWithTemplate () {
      if (!this.selectedTemplate) {
        this.$_notifyFailure('Please select template')
      } else {
        this.$emit('continueWithTemplate', this.selectedTemplate.settings)
      }
    },
    setAltImgForThumbnail (event, title) {
      const altText = encodeURIComponent(title)
      event.target.src = `https://dummyimage.com/1280X720/009687/ffffff.png&text=${altText}`
    },
    setSelectedTemplate(template) {
      this.selectedTemplate = this.$_.find(this.templates, t => t === template)
      this.$emit('templateSelected', this.selectedTemplate.settings)
    }
  }
}
</script>
<style scoped>
.unselected-template{
  cursor: pointer;
  border: thick solid rgba(0,0,0,.12)!important;
}
.selected-template{
  cursor: pointer;
  border: thick solid rgba(var(--vs-primary), 1)!important;
}
</style>