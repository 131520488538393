<template>
  <div class="my-3 file-list-container">
     <img v-if="params.data.fileType === 'image'" :src="params.data.previewUrl" 
      class="file-list-img">
      <feather-icon title="Document" v-if="params.data.fileType === 'generic'" icon="FileIcon" svgClasses="h-10 w-10 hover:text-primary cursor-pointer" />
      <!-- <feather-icon title="Edit" class="mt-2" icon="Edit3Icon" svgClasses="h-5 w-5 ml-4 hover:text-primary cursor-pointer" @click="onEdit" /> -->
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
    // this.params.context.componentParent.onThumbnailMounted(this.params.data, this.params.rowIndex) 
  },
  methods: {
    editRecord () {
      // this.params.context.componentParent.onEditImage(this.params.data, this.params.rowIndex) 
    }
  }
}
</script>
<style>
  /* .file-list-container {
    height: 40px;
  } */
  .file-list-container .file-list-img {
    border-radius: 2px;
    height: 35px;
  }
</style>
