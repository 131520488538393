<template>
  <div class="mt-2" v-if="settings && show">
    <div class="vx-col w-full">
      <vs-button @click="showModal=true" icon-pack="feather" icon="icon-chevron-right" icon-after class="w-full" color="primary">{{ settings.title }}</vs-button>
    </div>
    <travio-modal v-if="showModal" width='800'>
      <h3 slot="header">{{'Edit ' + settings.title}}</h3>
      <div slot="body">
        <div v-for="(formField, index) in formFieldSettings" :key="index">
          <component 
            :is="formField.component" 
            v-model="settingsData[formField.key]"
            v-bind="{ settings: formField.settings, applicationId: applicationId, allValues: settingsData  }">
          </component>
        </div>
      </div>
      
      <div slot="footer" class="flex flex-wrap justify-center">
        <vs-button class="" color="primary" @click="onSave" type="filled">Save</vs-button>      
        <vs-button class="ml-2" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
      </div>
    </travio-modal>
  </div>
</template>

<script>
import TravioWidgetSlider from "./TravioWidgetSlider"
import TravioWidgetColorPicker from "./TravioWidgetColorPicker"
import TravioWidgetSelect from "./TravioWidgetSelect"
import TravioWidgetInput from "./TravioWidgetInput"
import TravioWidgetRichText from './TravioWidgetRichText.vue'
import TravioWidgetFileManager from './TravioWidgetFileManager.vue'
import TravioWidgetDateTime from './TravioWidgetDateTime.vue'
import TravioWidgetSwitch from './TravioWidgetSwitch.vue'
import TravioWidgetCollectionSet from './TravioWidgetCollectionSet.vue'
import TravioWidgetUnitValue from './TravioWidgetUnitValue.vue'
import TravioWidgetAutoComplete from './TravioWidgetAutoComplete'
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  components: {
    TravioWidgetSlider,
    TravioWidgetColorPicker,
    TravioWidgetSelect,
    TravioWidgetInput,
    TravioWidgetRichText,
    TravioWidgetDateTime,
    TravioWidgetSwitch,
    TravioWidgetFileManager,
    TravioWidgetUnitValue,
    TravioWidgetAutoComplete,
    // recursive components
    TravioWidgetPopupSettings: () => import('./TravioWidgetPopupSettings.vue'),
    TravioWidgetCollectionSet: () => import('./TravioWidgetCollectionSet.vue'),
    TravioModal
  },
  props:["value", "settings", "applicationId"],
  props: {
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true }
  },
  data () {
    return {
      settingsData: {},
      showMainPopup: false,
      showModal:false
    }
  },
  computed: {
    formFieldSettings () {
      return applicationWidgetUtils.buildFormFields(this.settings.properties)
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  watch: {
    settingsData: {
      deep: true,
      handler: _.debounce(function (val){
      }, 250)
    }
  },
  mounted () {
    this.settingsData = applicationWidgetUtils.buildSettingsData(this.value)
  },
  methods: {
    onSave (value) {
      //Emitting a copy to prevent binding to parent
      this.$emit('input', JSON.parse(JSON.stringify(this.settingsData)))
      this.showModal = false
    },
    onCancel () {
      //reset data so when we reopen form data is still based on parent's value
      this.settingsData = applicationWidgetUtils.buildSettingsData(this.value)
      this.showModal = false
    }
  }
}

</script>

<style>
</style>