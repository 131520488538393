<template>
  <div v-if="settings && show" class="widget-image-set">
    <h6 class="text-primary mt-4 mb-4">{{ settings.title }}</h6>
    <ag-grid-vue
      v-show="value && value.length && value.length > 0"
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material w-100"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :rowDragManaged="true"
      :animateRows="true"
      :context="context"
      :headerHeight="0"
      :rowHeight="rowHeight"
      :domLayout="domLayout"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
    
    <div class="vx-col w-full">
      <vs-button @click="onAddFile" class="w-full" color="primary">{{ addButtonCaption }}</vs-button>
    </div>
    <travio-modal v-if="showAddModal" width='700' height="520">
      <h3 slot="header">{{'Add ' + modalTitle}}</h3>
      <div slot="body">
        <div v-for="(formField, index) in formFieldSettings" :key="index">
          <component 
            :is="formField.component" 
            v-model="newFile[formField.key]"
            v-bind="{ settings: formField.settings, applicationId: applicationId, allValues: isBlockTypeFormBuilder ? newFile : allValues}">
          </component>
        </div>
      </div>
      
      <div slot="footer" class="flex flex-wrap justify-center">
        <vs-button class="" color="primary" @click="onSaveAdd" type="filled">Save</vs-button>      
        <vs-button class="ml-2" color="danger" @click="onCancelAdd" type="filled">Cancel</vs-button>      
      </div>
    </travio-modal>

    <travio-modal v-if="showEditModal" width='700' height="520">
      <h3 slot="header">{{'Edit ' + modalTitle}}</h3>
      <div slot="body">
        <div v-for="(formField, index) in formFieldSettings" :key="index">
          <component 
            :is="formField.component" 
            v-model="newFile[formField.key]"
            v-bind="{ settings: formField.settings, applicationId: applicationId, allValues: isBlockTypeFormBuilder ? newFile : allValues, widgetUrl: widgetUrl, widgetInstanceId: widgetInstanceId }">
          </component>
          
        </div>
      </div>
      
      <div slot="footer" class="flex flex-wrap justify-center">
        <vs-button class="" color="primary" @click="onSaveEdit" type="filled">Save</vs-button>      
        <vs-button class="ml-2" color="danger" @click="onCancelEdit" type="filled">Cancel</vs-button>      
      </div>
    </travio-modal>
    
  </div>
</template>

<script>
import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import CellRendererImageSetActions from './../cell-renderers/CellRendererImageSetActions.vue'

import TravioWidgetAutoComplete from './TravioWidgetAutoComplete'
import TravioWidgetSlider from "./TravioWidgetSlider"
import TravioWidgetColorPicker from "./TravioWidgetColorPicker"
import TravioWidgetSelect from "./TravioWidgetSelect"
import TravioWidgetInput from "./TravioWidgetInput"
import TravioWidgetRichText from './TravioWidgetRichText.vue'
import TravioWidgetImagePicker from './TravioWidgetImagePicker.vue'
import TravioWidgetFileManager from './TravioWidgetFileManager.vue'
import TravioWidgetDateTime from './TravioWidgetDateTime.vue'
import TravioWidgetSwitch from './TravioWidgetSwitch.vue'
import TravioWidgetPopupSettings from './TravioWidgetPopupSettings.vue'
import TravioWidgetUnitValue from './TravioWidgetUnitValue.vue'

import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  components: {
    TravioModal,
    TravioWidgetSlider,
    TravioWidgetColorPicker,
    TravioWidgetRichText,
    TravioWidgetDateTime,
    TravioWidgetImagePicker,
    TravioWidgetSwitch,
    TravioWidgetUnitValue,
    CellRendererImageSetActions,
    AgGridVue,
    TravioWidgetInput,
    TravioWidgetSelect,
    TravioWidgetFileManager,
    TravioWidgetAutoComplete,
    // recursive component
    TravioWidgetPopupSettings: () => import('./TravioWidgetPopupSettings.vue'),
    TravioWidgetCollectionSet: () => import('./TravioWidgetCollectionSet.vue')
  },
  
  props:{
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true },
    widgetUrl: { required: false },
    widgetInstanceId: { required: false }
  },
  data () {
    return {
      settingsData: {},
      rowData: [],
      showMainPopup: false,
      showAddModal:false,
      showEditModal: false,

      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      cellRendererComponents: {
        CellRendererImageSetActions
      },
      context: null,
      // Makes the grid height adjust based on number of records
      domLayout: 'autoHeight',
      rowHeight: null,
      newFile: {},
      editingRowIndex: null,
      addButtonCaption: 'Add',
      modalTitle: '',
      // This will determine how we passed allSettings to a widget component
      // if false we'll pass the top level root value, else we'll pass this components settings
      isBlockTypeFormBuilder: false
    }
  },
  computed: {
    formFieldSettings () {
      if (!this.settings.items) return {}
      const formFields = applicationWidgetUtils.buildFormFields(this.settings.items.properties)
      return formFields
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.settingsData = applicationWidgetUtils.buildSettingsData(newVal)
    }
  },
  beforeMount() {
    this.gridOptions = {};



    if(this.settings.items.title) {
      this.addButtonCaption = "ADD " + (this.settings.items.title && this.settings.items.title.toUpperCase()) || ""
      this.modalTitle = this.settings.items.title
    }

    const blockItemProps = Object.entries(this.settings.items.properties) 
    
    for (const [key, val] of blockItemProps) {
      const prop = this.settings.items.properties[key]
      // This will determine how we passed allSettings to a widget component
      // if false we'll pass the top level root value, else we'll pass this components settings
      if (prop['showIf']) {
        this.isBlockTypeFormBuilder = true
        break
      }
    }    

    let columnDefs = []
    if (this.settings.displayField) {
      // This will apply to most cases
      const displayFieldProp = this.settings.items.properties[this.settings.displayField]
      const displayFieldName = (displayFieldProp['enum'] && displayFieldProp['enumNames']) ? 'TypeFriendlyValue' : this.settings.displayField
      columnDefs.push({ 
        headerName: 'Display Field Enum',
        field: displayFieldName ,
        width: 280,
        rowDrag: true,
        suppressSizeToFit: false })

    } else {
      // Edge case for Location prop, which doesn't specify a display field
      const locationField = Object.keys(this.settings.items.properties)[0]
      columnDefs.push({ 
        headerName: 'Display Field Enum',
        field: locationField,
        valueGetter: params => JSON.stringify(params.data[locationField]),
        width: 280,
        rowDrag: true,
        suppressSizeToFit: false })
    }

    columnDefs.push({ headerName: 'Action', suppressSizeToFit: true, width: 80, cellRendererFramework: Vue.extend(CellRendererImageSetActions) })
    this.columnDefs = columnDefs
    
    
    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead

    this.rowHeight = 36;
    this.defaultColDef = {
      flex: 1,
      minWidth: 50,
      //important to set this to false since rowDrag is enabled
      sortable: false,
      resizable: true,
      suppressMenu: true
    }
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted () {
    this.settingsData = (this.value && applicationWidgetUtils.buildSettingsData(this.value)) || {}
    this.gridApi = this.gridOptions.api || {};
    this.gridColumnApi = this.gridOptions.columnApi || {};
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit();
    // TODO, update image order since not automatically done by Ag Grid
    this.gridOptions.onRowDragEnd = (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.rowData, x => x.ArtificialId == rowNodeGettingDragged.data.ArtificialId)
      const toIndex = e.overIndex
      array_move(this.rowData, fromIndex, toIndex)

      
      this.rowData = JSON.parse(JSON.stringify(this.rowData))
      // Remove Artificial id before emitting value, since not part of the schema
      const outputValue = this.mapRowDataToPersistedValue()
      this.$emit('input', JSON.parse(JSON.stringify(outputValue)))

    }
  },
  methods: {
    onSaveAdd (value) {
      this.saveEnumFriendlyValue(this.newFile)
      this.rowData.push(this.newFile)
      this.rowData = JSON.parse(JSON.stringify(this.rowData))
      // Emitting a copy to prevent binding to parent
      const outputValue = this.mapRowDataToPersistedValue()
      this.$emit('input', JSON.parse(JSON.stringify(outputValue)))

      this.resetCurrentFile()
      // Rebuild settings data since emitting input, updates this.value
      
      this.showAddModal = false
      // Make the currently visible columns fit the screen
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
      
    },
    onSaveEdit (value) {
      if (this.editingRowIndex === null) return
      this.saveEnumFriendlyValue(this.newFile)
      this.rowData[this.editingRowIndex] = Object.assign({}, this.newFile)
      this.rowData = JSON.parse(JSON.stringify(this.rowData))

      const outputValue = this.mapRowDataToPersistedValue()

      //Emitting a copy to prevent binding to parent
      this.$emit('input', JSON.parse(JSON.stringify(outputValue)))
      this.resetCurrentFile()
      this.editingRowIndex = null
      this.showEditModal = false
      // Make the currently visible columns fit the screen
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    mapRowDataToPersistedValue() {
      // remaps row data to remove extra props that will not be saved
      return JSON.parse(JSON.stringify(this.rowData)).map(x => {
        delete x.ArtificialId
        delete x.TypeFriendlyValue
        return x
      })
    },
    onCancelAdd () {
      this.resetCurrentFile()
      this.showAddModal = false
    },
    onCancelEdit () {
      this.resetCurrentFile()
      this.showEditModal = false
    },
    resetCurrentFile () {
      this.newFile = {}
    },
    onGridReady () {
      if (this.value) {
        let rowData = JSON.parse(JSON.stringify(this.value))
        rowData.forEach((row, index) => {
          row.ArtificialId = index
          // In popup widget, type is saved as code when selected from drop-down
          this.saveEnumFriendlyValue(row)
        });
        this.rowData = JSON.parse(JSON.stringify(rowData))
      }

      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    saveEnumFriendlyValue(row) {
      const displayFieldProp = this.settings.items.properties[this.settings.displayField]
      if (displayFieldProp && displayFieldProp['enum'] && displayFieldProp['enumNames']) {
        // Expected only 2 props in row, see before mount
        const prop = Object.keys(row).find(x => x != 'ArtificialId')
        const enumKey = row.Type || row[prop]
        row.TypeFriendlyValue = displayFieldProp.enumNames[enumKey]
      } 
    },
    onAddFile () {
      //dynamically create as settings value for the add popup
      const maxArtificialId = Math.max.apply(Math, this.rowData.map(o => o.ArtificialId))
      const propKeys = Object.keys(this.settings.items.properties)

      let theNewFile = this.newFile
      let theSettings = this.settings
      _.forEach(propKeys, (item, index) => {
        theNewFile[item] = theSettings.items.properties[item].default || ""
        theNewFile['ArtificialId'] = maxArtificialId + 1
      })
      this.newFile = Object.assign({}, theNewFile)
      this.showAddModal = true
    },
    
    onEditImage (image, rowIndex) {
      
      const propKeys = Object.keys(this.settings.items.properties)
      let newFile = this.newFile
      let settingsData = this.settingsData
      _.forEach(propKeys, (item, index) => {
        newFile[item] = settingsData[rowIndex][item]
      })

      this.newFile = Object.assign({}, newFile)
      //Image has no id so we just rely on the row index
      this.editingRowIndex = rowIndex
      this.showEditModal = true
    },
    onDuplicateImage (image, rowIndex) {
      const maxArtificialId = Math.max.apply(Math, this.rowData.map(o => o.ArtificialId))
      const duplicate = Object.assign({}, this.rowData[rowIndex], { ArtificialId: maxArtificialId + 1 } )
      this.rowData.push(duplicate)
      this.rowData = JSON.parse(JSON.stringify(this.rowData))
      //Emitting a copy to prevent binding to parent
      this.$emit('input', JSON.parse(JSON.stringify(this.rowData)))
      // Make the currently visible columns fit the screen
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onDeleteImage (image, rowIndex) {

      this.rowData.splice(rowIndex, 1) 
      this.rowData = JSON.parse(JSON.stringify(this.rowData))
      //Emitting a copy to prevent binding to parent
      this.$emit('input', JSON.parse(JSON.stringify(this.rowData)))
      // Make the currently visible columns fit the screen
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
  }
}

</script>

<style>
.widget-image-set .ag-theme-material .ag-cell{
  padding-left: 3px !important;
}
</style>