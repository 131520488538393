<template>
  <div class="mt-6" v-if="settings && show">
    <h6 class="text-primary">{{ settings.title }}</h6>
    <div class="vx-row">
      <div class="vx-col w-4/5">
        <div class="mt-4 ml-3">
          <!-- https://nightcatsama.github.io/vue-slider-component/#/api/props -->
          <vue-slider 
            :value="sliderValue"
            :interval="dynamicInterval" 
            :dragOnClick="true" 
            @change="updateSettings"
            :min="min" :max="max" 
            tooltip="none" />
        </div>
      </div>
      <div class="vx-col w-1/5 pl-0 widget-slider-input">
        <input class="w-full" type="number"
          :value="sliderValue"
          :min="min"
          :max="max"
          @input="updateSettings($event.target.value)"
        >
      </div>
      
    </div>
  </div>
</template>

<script>
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  components: {
  },
  props:{
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true }
  },
  data () {
    return {
      sliderValue: null,
    }
  },

  mounted () {
    if( this.value ) {
      this.sliderValue = this.value < this.settings.minimum 
        ? this.settings.minimum 
        : this.value
    } else {
      this.sliderValue = this.settings.minimum || 0
    }
  },
  computed: {
    min () {
      return this.settings.minimum || 0
    },
    max () {
      return this.settings.maximum || 100000
    },
    dynamicInterval () {
      // interval can be different based on min and max settings, e.g. for min = 0 and max 100, interval is 1, min = 0 and max 99.99 interval is 0.01
      return this.settings.step || 1
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  methods: {
    updateSettings (value) {
      // this.value = value
      this.sliderValue = parseInt(value)
      this.$emit('input', parseInt(value))
    }
  }
}
</script>
<style scoped>
.widget-slider-input input {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    
}
.widget-slider-input input:focus {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(var(--vs-primary),1)!important;
}
</style>
