<template>
  <div class="widget-image-cards">
    <div class="vx-row match-height">
      <!-- <vx-card style="cursor: pointer">
        <div slot="no-body">
          <img :src="widget.thumbnail" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
      </vx-card> -->

      <div class="vx-col mb-base md:w-1/6 sm:w-1/4 xs:w-full" 
        v-for="(file, index) in this.fileList" 
        :key="index">
        <div class="scaling" @click="onFullScreen(file)"
          v-bind:style="{ backgroundImage: 'url(' + file.fullUrl + '?auto=compress&amp;cs=tinysrgb&amp;h=350)' , backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', cursor: 'pointer' }">
        </div>
      </div>

    </div>
    <FsLightbox
      :toggler="fullScreenToggle"
      :sources="imageListUrl"
      :slide="fullScreenCurrentIndex"
      type="image"
      :types="[null, null, 'video']"
      :customToolbarButtons="[
        {
        viewBox: '0 0 24 24',
        d: 'M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z',
        width: '17px',
        height: '17px',
        title: 'Select File',
        onClick: onSelectedFile
        }
      ]"
    />
  </div>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import CellRendererImageSetActions from './../cell-renderers/CellRendererImageSetActions.vue'
import TravioWidgetInput from "./TravioWidgetInput"
import TravioWidgetImagePicker from "./TravioWidgetImagePicker"
import FsLightbox from "fslightbox-vue"
import * as appWidgetUtils from '../applicationWidgetUtils.js'

export default {
  components: {
    TravioModal,
    TravioWidgetInput,
    TravioWidgetImagePicker,
    FsLightbox
  },
  props:{
    fileList: { required: true, type: Array },
    applicationId: {required: true }
  },
  data () {
    return {
      fullScreenToggle: false,
      fullScreenCurrentIndex: 0,
    }
  },
  computed: {
    imageListUrl () {
      return this.fileList.map(x => { 
        return x.fullUrl
      })
    }
  },
  mounted () {
  },
  methods: {
    onFullScreen (file) {
      const currentIndex = this.$_.findIndex(this.fileList, x => x.fullUrl == file.fullUrl)
      this.fullScreenCurrentIndex = currentIndex + 1 
      this.fullScreenToggle = !this.fullScreenToggle
    },
    onSelectedFile(file) {
      this.$emit("onSelectedFile", this.imageListUrl[this.fullScreenCurrentIndex])
    }
  }
}

</script>
<style scoped>
  .widget-image-cards .scaling {
    height: 200px;
    width: 100%;
  }  
</style>
