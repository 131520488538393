<template>
  <div v-if="settings && show" class="mt-8 mb-2">
    <h6 class="text-primary mb-2">{{ settings.title }}</h6>
    <label class="text-sm">{{ settings.description || '' }}</label>
    <div class="mt-2" v-if="settings.format === 'date'">
        <datetime type="date" v-model="dateModel" :title="settings.title" input-class="v-datetimepickertxt" format="yyyy-MM-dd" @input="updateSettings" clearable></datetime>
    </div>
    <div class="mt-2" v-else-if="settings.format === 'time'">
        <datetime type="time" v-model="dateModel" :title="settings.title" input-class="v-datetimepickertxt" format="HH:mm" use12-hour @input="updateSettings" clearable></datetime>
    </div>
    <div class="mt-2" v-else-if="settings.format === 'date-time'">
        <datetime type="datetime" v-model="dateModel" :title="settings.title" input-class="v-datetimepickertxt" format="yyyy-MM-dd HH:mm" use12-hour @input="updateSettings" clearable></datetime>
    </div>
  </div>
</template>

<script>

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  props: {
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true }
  },
  components: {
    datetime: Datetime
  },
  data () {
    return {
      dateModel: ''
    }
  },
  computed: {
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  created () {
    this.dateModel = this.value
  },
  mounted () {
    
  },
  methods: {
    updateSettings (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>
  .v-datetimepickertxt {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    width: 100%;
  }
</style>
