<template>
  <div class="widget-select-editor mt-8 mb-2" v-if="settings && show">
    <h6 class="text-primary mb-2">{{ settings.title }}</h6>
    <div class="text-sm">{{ settings.description || '' }}</div>
    
    <!-- Multi select dropdown -->
    <v-select v-if="allowMultiples" class="mb-4"
      :value="selectedValue"
      @input="updateSettings" 
      :options="options"
      multiple />
    
    <!-- Icon select dropdown -->
    <!-- https://vue-select.org/api/slots.html#selected-option -->
    <!-- https://vue-select.org/api/slots.html#option -->
    <v-select v-else-if="settings.format==='icon'" :options="options" :value="selectedValue" @input="updateSettings" hide-details outlined>
      <template #selected-option="{ code }">
        <div><span v-html="code"></span></div>
      </template>
      <template #option="{ code, label }">
        <div v-html="code"></div>
      </template>
    </v-select>
    
    <!-- Font Selection -->
    <template v-else-if="settings.format === 'font'">
      <div class="flex flex-wrap justify-end">
        <div class="mt-2 mb-2">Free Text</div>
        <vs-switch class="mt-2 mb-2 ml-3" v-model="isFreeText" @input="updateFreeTextOption" />
      </div>
      <v-select v-if="!isFreeText" class="mb-4"
        :value="selectedValue"
        taggable
        @input="updateSettings" 
        :options="fontFamilies"> 

        <template #selected-option="{ code }">
          <span :style="{ fontFamily: code }">{{ code }}</span>
        </template>
        <template #option="{ code, label }">
          <span :style="{ fontFamily: code }">{{ code }}</span>
        </template>

      </v-select>
      <vs-input :value="freeTextValue" v-if="isFreeText" @input="updateSettingsViaFreeText"  class="w-full mt-2"></vs-input>
    </template>
    

    <!-- Standard select dropdown -->
    <v-select v-else class="mb-4"
      :value="selectedValue"
      @input="updateSettings" 
      :options="options" />
  </div>
</template>

<script>
import * as applicationWidgetUtils from '../applicationWidgetUtils.js'

export default {
  props:{
    value: { required: true },
    settings: { required: true },
    applicationId: {required: true},
    allValues: { required: true }
  },
  data () {
    return {
      selectedValue: null,
      isFreeText: false,
      freeTextValue: null
    }
  },
  computed: {
    allowMultiples () {
      return this.settings.allowMultiples
    },
    options () {
      if(this.settings.enumNames && (Object.keys(this.settings.enumNames).length === this.settings.enum.length )) {
        return this.$_.map(Object.keys(this.settings.enumNames), x => {
          return { code: x, label: this.settings.enumNames[x] }
        })
      } else if (this.settings.enum) {
        return this.$_.map(this.settings.enum, x => {
          return { code: x, label: x }
        })
      }
    },
    fontFamilies () {
      return [
        { code: 'Arial, Helvetica, sans-serif', label: 'Arial, Helvetica, sans-serif' },
        { code: 'Arial Black, Gadget, sans-serif', label: 'Arial Black, Gadget, sans-serif' },
        { code: 'Comic Sans MS, cursive, sans-serif', label: 'Comic Sans MS, cursive, sans-serif' },
        { code: 'Courier New, Courier, monospace', label: 'Courier New, Courier, monospace' },
        { code: 'Georgia, serif', label: 'Georgia, serif' },
        { code: 'Impact, Charcoal, sans-serif', label: 'Impact, Charcoal, sans-serif' },
        { code: 'Lucida Console, Monaco, monospace', label: 'Lucida Console, Monaco, monospace' },
        { code: 'Lucida Sans Unicode, Lucida Grande, sans-serif', label: 'Lucida Sans Unicode, Lucida Grande, sans-serif' },
        { code: 'Palatino Linotype, Book Antiqua, Palatino, serif', label: 'Palatino Linotype, Book Antiqua, Palatino, serif' },
        { code: 'Tahoma, Geneva, sans-serif', label: 'Tahoma, Geneva, sans-serif' },
        { code: 'Trebuchet MS, Helvetica, sans-serif', label: 'Trebuchet MS, Helvetica, sans-serif' },
        { code: 'Times New Roman, Times, serif', label: 'Times New Roman, Times, serif' },
        { code: 'Verdana, Geneva, sans-serif', label: 'Verdana, Geneva, sans-serif' }
      ]
    },
    show () {
      return applicationWidgetUtils.showField(this.settings, this.allValues)
    }
  },
  mounted () {
    if(this.allowMultiples) {
      this.selectedValue = this.$_.filter(this.options, x => this.$_.includes(this.value, x.code)) || []
    } else if (this.settings.format === 'font') {
      const selectedValue = this.$_.find(this.fontFamilies, x => x.code == this.value) || null
      if (selectedValue || !this.value) {
        this.selectedValue = selectedValue  
      } else {
        this.freeTextValue = this.value
        this.isFreeText = true
      }
      
    } else {
      this.selectedValue = this.$_.find(this.options, x => x.code == this.value) || {}
    }
    
  },
  methods: {
    updateSettings (value) {
      this.selectedValue = value;
      if(this.allowMultiples) {
        // Value will be saved as an array
        this.$emit('input', this.$_.map(value, x => x.code))
      } else if (this.settings.format === 'font') {
        // Font types can be set to value outside list, only label prop is set on this case
        this.$emit('input', value ? value.label : '')
      }
      else {
        this.$emit('input', value.code)
      }
      
    },
    updateSettingsViaFreeText (value) {
      this.freeTextValue = value
      this.$emit('input', value)
    },
    updateFreeTextOption (value) {
      if(value) {
        this.selectedValue = {}
      } else {
        this.freeTextValue = ''
      }
      this.$emit('input', '')
    }
  }
}
</script>

<style>
.widget-editor-svglight svg {
    fill: #FFFFFF;
}
</style>
